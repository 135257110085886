$toggleFullWidth: 66px;
$toggleFullHeight: 30px;

$toggleCircleDiameter: $toggleFullHeight - 8px;
$toggleCirclePadding: 3px; /* padding between circle and the sides */

$toggleTextPadding: 8px; /* padding between text and the side */
$toggleBorderRadius: 30px;

$toggleAnimationDuration: .4s;
$toggleBorderSize: 2px;

$toggleDisabledColor: $grey-dark;
$toggleEnabledColor: $active-color;
