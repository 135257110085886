@import "../../variables.scss";

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 -10vw;
  padding: 0;
}

.termsBody {
  text-align: left;
}

.separator {
  padding: $spacing-xs / 2;
  display: block;
}
