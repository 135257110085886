@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding: 14px 0;

  a {
    color: $main-text-color;
  }

  p {
    color: $main-text-color;
  }
}
