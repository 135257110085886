@import "../../variables.scss";

.page {
  composes: page from "../../style/index.scss";
  justify-content: space-between;
  padding: $spacing-xs;
  background-color: $background-color;
}

.slider {

  > div {
    height: 100%;
  }

  ul {
    height: 100%;
  }
}

.sliderContainer {
  text-align: center;
  padding-bottom: $spacing-xxs;
  display: flex;
  flex: 1 1;
  justify-content: center;
  max-width: 100%;
  overflow-x: hidden;
}

/* Carousel */
.embla {
  max-width: 100%;
  --slide-height: 100%;
  --slide-spacing: 0;
  --slide-size: 100%;

  display: flex;
  height: 100%;
  flex-direction: column;
}

.emblaViewport {
  overflow: hidden;
  height: 100%;
  justify-content: center;
  display: flex;
  max-width: 100%;
  flex-shrink: 1;
  margin: auto;
}

.emblaContainer {
  display: flex;
  touch-action: pan-y pinch-zoom;
  max-width: 100%;
}

.emblaSlide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.emblaSlideNumber {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}

.emblaControls {
  display: flex;
  margin-top: auto;
  justify-content: center;
}

.emblaButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;

  .emblaButton {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      color: var(--detail-high-contrast);
    }
  }

}

.emblaDots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  .emblaDot {
    padding: 0;
    border-radius: 50%;
    background: #b7b8ba;
    width: 5px;
    height: 5px;
    margin: 10px;
    border: 0;

    &.emblaDotSelected {
      background: var(--active-color);
    }
  }
}

