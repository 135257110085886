@import "../../../variables.scss";
@import "./variables.scss";

.toggleButtonContainer {
  position: relative;
  background: $grey-dark;
  min-width: $toggleFullWidth;
  height: $toggleFullHeight;
  border-radius: $toggleBorderRadius;
  display: inline-flex;
  width: fit-content;
  margin: auto 0;

  * {
    transition: all $toggleAnimationDuration;
  }

  .toggleButton {
    background-color: $secondary-color;
    border-color: $secondary-text-color;

    .yes,
    .no {
      transition: color ($toggleAnimationDuration / 2);
      color: $secondary-text-color;
    }

    .yes {
      padding-left: $toggleTextPadding;
    }

    .no {
      padding-right: $toggleTextPadding;
      margin-left: auto;
    }

    i {
      background-color: $secondary-text-color;
    }

    &.active {
      background-color: $toggleEnabledColor;
      border-color: $toggleEnabledColor;

      .yes,
      .no {
        color: $active-text-color;
      }

      i {
        background-color: $active-text-color;
      }
    }

  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $toggleCircleDiameter;
    height: $toggleCircleDiameter;
    background: $toggleDisabledColor;
    border-radius: 50%;
  }

  input {
    display: none;

    + div {
      display: flex;
      width: 100%;
      align-items: center;
      border-radius: 30px;
      border: $toggleBorderSize solid $toggleDisabledColor;
      height: $toggleFullHeight;
    }

    &:checked + div {

      i {
        left: calc(100% - (#{$toggleCircleDiameter} + #{$toggleCirclePadding}));
      }

      span:first-child {
        color: $secondary-text-color;
      }
    }

    &:not(:checked) + div {
      background-color: $white;
      border-color: $toggleDisabledColor;

      i {
        background-color: $toggleDisabledColor;
        left: $toggleCirclePadding;
      }

      span:last-child {
        color: $toggleDisabledColor;
      }
    }
  }
}
