@import "../../../../../../variables.scss";
@import "../../../../../common/toggleButton/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px 0;
  line-height: $toggleFullHeight;
}

.label {
  line-height: initial;
  text-align: left;
  padding: 6px 0;
  color: $main-text-color;

  a {
    color: $main-text-color;
  }
}

.toggleButtonWrapper {
  margin-left: 3px;
}
